.features {
  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
  }

  &__swiper {
    width: 100%;
    padding-bottom: 100px;

    .swiper-slide {
      width: 26%;
      min-width: 200px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        height: 120px;
        width: 2px;
        background-color: $color--arsenic;
        opacity: 0.2;
      }

      &:last-of-type::before {
        display: none;
      }
    }

    &__image {
      width: 160px;
      height: 130px;
      margin-bottom: 40px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__description {
      max-width: 250px;
      font-family: $font-family--secondary;
      font-size: $font-size--xregular;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 1px;
      text-align: center;
      color: $color--dark-imperial-blue;
    }

    &__buttons {
      display: none;
    }
  }

  @include medium {
    &__swiper {
      &__description {
        font-size: $font-size--medium;
      }

      &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 60px;
      }

      &__button {
        position: static;
        margin: 0 80px;
        background-image: none;
        width: 20px;
        height: auto;
        stroke: $color--turquoise-surf;
        transition: 0.2s $easing--standard;

        &:hover {
          stroke: $color--dark-imperial-blue;
        }
      }
    }
  }
}
