.links {
  padding-bottom: 100px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
  }

  &__link {
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
    color: $color--turquoise-surf;
    font-size: $font-size--medium;
    line-height: 1.2;
    margin: 20px;
    padding-bottom: 15px;
    position: relative;
    width: 100%;

    svg {
      margin-left: auto;
      margin-bottom: 4px;
      width: 12px;
      stroke: $color--turquoise-surf;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color--turquoise-surf;
      transform-origin: bottom;
      transition: 0.2s $easing--standard;
    }

    &--dark {
      color: $color--dark-imperial-blue;

      svg {
        stroke: $color--dark-imperial-blue;
      }

      &::before {
        background-color: $color--dark-imperial-blue;
      }
    }

    &:hover {
      &::before {
        transform: scaleY(2);
      }
    }
  }

  &__text {
    max-width: 220px;
  }

  @include small {
    &__container {
      flex-direction: row;
      padding: 0 20px;
    }

    &__link {
      width: auto;

      svg {
        margin-left: 20px;
      }
    }
  }

  @include medium {
    &__link {
      font-size: $font-size--xmedium;
      padding-bottom: 20px;
      margin: 20px 80px;

      svg {
        margin-left: 10px;
        margin-bottom: 8px;
        width: 25px;
      }
    }

    &__text {
      max-width: 280px;
    }
  }
}
