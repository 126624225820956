// Colors
// -------------------------
$color--turquoise-surf: #00bdc2;
$color--bright-turquoise: #0ce2e8;
$color--dark-imperial-blue: #00576b;
$color--arsenic: #434343;
$color--platinum: #e0e0e0;
$color--anti-flash-white: #f0f3f4;
$color--white: #fff;

// Fonts
// -------------------------
$font-family--primary: 'proxima-nova', sans-serif;
$font-family--secondary: 'Raleway', sans-serif;

$font-size--base: 16px;
$font-size--xxxlarge: 5rem; // 80px
$font-size--xlarge: 3.4rem; // 54.4px
$font-size--large: 2.8rem; // 44.8px
$font-size--xmedium: 2rem; // 32px
$font-size--medium: 1.5rem; // 24px
$font-size--xregular: 1.1rem; // 17.6px
$font-size--regular: 1rem; // 16px
$font-size--small: 0.8rem; // 12.8px

// Transitions
// -------------------------

$easing--standard: cubic-bezier(0.4, 0, 0.2, 1);
$easing--decelerate: cubic-bezier(0, 0, 0.2, 1);
$easing--accelerate: cubic-bezier(0.4, 0, 1, 1);

// Responsive breakpoints
// -------------------------
$breakpoints: (
  xsmall: 400px,
  small: 640px,
  medium: 960px,
  large: 1200px,
  xlarge: 1600px
);
