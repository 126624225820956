.opportunities {
  padding: 150px 0 50px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 55%;
    width: 100%;
    background-color: $color--anti-flash-white;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s $easing--standard;
  }

  &.active::before {
    transform: scaleY(1);
  }

  &__intro {
    .section__slogan {
      opacity: 0;
      transform: translateY(20px);

      .active & {
        opacity: 1;
        transform: translateY(0);
        transition: 0.2s $easing--standard 0.2s;

        &:nth-of-type(2) {
          transition-delay: 0.35s;
        }
      }
    }
  }

  &__tabs {
    margin-top: 30px;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &__content {
      opacity: 0;
      transform: translateY(20px);
      padding: 0 40px;
      width: 100%;

      .active & {
        opacity: 1;
        transform: translateY(0);
        transition: 0.2s $easing--standard 1s;
      }
    }

    &__switches {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      flex-wrap: wrap;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 20px;

      &--fuel {
        flex-wrap: nowrap;
      }
    }
  }

  &__tab {
    display: none;

    &__image {
      object-fit: contain;
      max-height: 300px;
      width: 100%;
      transition: 0.2s $easing--standard;
    }

    &__description {
      text-align: center;
      margin-bottom: 20px;
    }

    &__title {
      font-family: $font-family--primary;
      font-size: $font-size--xmedium;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &__paragraph {
      font-family: $font-family--secondary;
      letter-spacing: 1px;
      line-height: 1.8;
    }

    &.active {
      display: block;
    }

    &.fade-in {
      animation: fadeIn 0.2s;
    }

    &.fade-out {
      animation: fadeOut 0.2s;
    }
  }

  &__switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transform: translateY(-20px);
    width: percentage(1/3);

    .active & {
      opacity: 1;
      transform: translateY(0);
      transition: 0.2s $easing--standard 0.65s;

      &:nth-of-type(2) {
        transition-delay: 0.75s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.85s;
      }
    }

    &--fuel {
      flex-direction: row;
      justify-content: space-between;
      z-index: 1;
      max-width: 150px;

      .opportunities__switch__container {
        display: none;

        &--main {
          display: flex;
        }
      }
    }

    &--fake {
      display: flex;
      flex-direction: column;

      .opportunities__switch__shoulder {
        display: block;
        width: 70%;
        top: 25px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 30px;

      &--main {
        width: 100%;
        margin-top: 0;

        .opportunities__switch__icon {
          font-size: 30px;
          font-family: $font-family--primary;
          font-weight: 700;
          stroke: none;
          fill: $color--dark-imperial-blue;
          transition: 0.2s $easing--standard;
          opacity: 1;

          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-color: $color--anti-flash-white;
          }
        }
      }
    }

    &__icon {
      height: 70px;
      width: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      stroke: $color--arsenic;
      opacity: 0.7;
      transition: 0.2s $easing--standard;

      svg {
        height: 60%;
        width: 60%;
        z-index: 1;
        position: relative;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: $color--turquoise-surf;
        transform-origin: center;
        transform: scale(0);
        transition: 0.2s $easing--standard;
        z-index: 1;
      }
    }

    &__shoulder {
      position: absolute;
      right: 100%;
      top: 35px;
      width: 40%;
      opacity: 0.7;
      display: none;

      svg {
        height: auto;
        width: auto;
        stroke: #707070;
      }

      &--right {
        right: auto;
        left: 100%;
        transform: rotateY(180deg);
      }
    }

    &__text {
      font-family: $font-family--secondary;
      font-size: $font-size--small;
      color: $color--arsenic;
      font-weight: 500;
      text-align: center;
      margin-top: 15px;
      user-select: none;
    }

    &__illustration {
      width: 100%;
      margin-bottom: 10px;
      padding: 0 16.5%;
      opacity: 0;
      transform: translateY(20px);

      .active & {
        opacity: 1;
        transform: translateY(0);
        transition: 0.2s $easing--standard 0.5s;
      }

      &--fuel {
        padding: 0 25%;
        display: none;
      }
    }

    &.active {
      pointer-events: none;

      .opportunities__switch__container--main {
        svg {
          fill: $color--white;
          stroke: none;
        }

        .opportunities__switch__shoulder svg {
          stroke: $color--turquoise-surf;
        }
      }

      .opportunities__switch__icon {
        opacity: 1;
        stroke: $color--white;

        &::before {
          transform: scale(1);
        }
      }
    }
  }

  @include medium {
    padding-bottom: 100px;

    &::before {
      height: 100%;
      width: 50%;
      transform: scaleX(0);
      transform-origin: left;

      &.active::before {
        transform: scaleX(1);
      }
    }

    &__switch {
      &__illustration--fuel {
        display: block;
      }

      &--fuel {
        flex-wrap: wrap;
        width: 50%;
        max-width: none;

        .opportunities__switch__container {
          display: flex;

          &--main {
            .opportunities__switch__text {
              display: none;
            }
          }
        }
      }

      &--fake {
        display: none;
      }

      &__shoulder {
        display: block;
      }
    }

    &__tabs {
      &__container {
        flex-direction: row;
        padding: 0 40px;
      }

      &__switches {
        width: 50%;
        max-width: none;
        align-content: flex-start;
        padding: 0 70px;

        &--fuel {
          padding: 0 30px;
          flex-wrap: wrap;
        }
      }

      &__content {
        width: 50%;
        padding: 0;
      }
    }

    &__tab {
      &__image {
        max-height: 430px;
        padding-left: 100px;
        object-position: left;
      }

      &__description {
        text-align: left;
        padding-left: 100px;
      }

      &__title {
        font-weight: 900;
        color: $color--platinum;
        font-size: $font-size--large;
      }
    }
  }
}
