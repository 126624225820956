.product {
  counter-reset: product;

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0 80px;

    & > .container {
      padding-bottom: 20px;
      background-color: $color--white;
      position: relative;
      z-index: 1;
    }
  }

  &__list {
    padding-bottom: 100px;
    padding: 0 20px;

    &__container {
      position: relative;
      padding: 0;
    }
  }

  &__decoration--top {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 50%;
    padding-left: 20%;
    z-index: -1;

    .a {
      stroke-width: 6;
    }

    .toper {
      stroke-dasharray: 14 14;
      stroke-width: 8;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px 0;
  }

  &__decoration--line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    padding-left: 20%;
    z-index: -1;

    .a {
      stroke-width: 10;
    }

    .toper {
      stroke-width: 12;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    &__decoration--top,
    &__decoration--line {
      display: none;
    }
  }

  &__image {
    margin-left: auto;
    width: 70%;
    position: relative;

    &__img {
      object-fit: contain;
      width: 100%;
      max-height: 600px;
    }

    &::before {
      content: '';
      width: 70%;
      height: 0;
      padding-bottom: 70%;
      border-radius: 50%;
      background-color: $color--turquoise-surf;
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: -1;
    }
  }

  &__description {
    padding-left: 30%;
    position: relative;

    &::before {
      counter-increment: product;
      content: '0' counter(product);
      position: absolute;
      left: 0;
      top: 0;
      font-size: $font-size--xmedium;
      font-weight: 900;
      color: $color--turquoise-surf;
      transition: 0.2s $easing--standard;
      transform: scale(0);
    }

    &::after {
      content: '';
      position: absolute;
      left: calc(20% - 25px / 2);
      top: 2px;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: $color--turquoise-surf;
      border: 5px solid $color--white;
      box-sizing: content-box;
      transition: 0.2s $easing--standard 0.2s;
      transform: scale(0);
    }

    &.active {
      &::before,
      &::after {
        transform: scale(1);
      }
    }
  }

  &__title {
    color: $color--dark-imperial-blue;
    font-family: $font-family--secondary;
    font-weight: 600;
    font-size: $font-size--medium;
    line-height: 1.2;
    margin-bottom: 20px;
    transform: translateY(10px);
    opacity: 0;

    .active & {
      transition: 0.2s $easing--standard 0.4s;
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__paragraph {
    font-family: $font-family--secondary;
    line-height: 1.5;
    transform: translateY(10px);
    opacity: 0;

    .active & {
      transition: 0.2s $easing--standard 0.6s;
      transform: translateY(0);
      opacity: 1;
    }
  }

  @include small {
    &__decoration--top {
      .a {
        stroke-width: 4;
      }

      .toper {
        stroke-dasharray: 10 10;
        stroke-width: 6;
      }
    }

    &__decoration--line {
      .a {
        stroke-width: 8;
      }

      .toper {
        stroke-width: 10;
      }
    }
  }

  @include medium {
    &__list {
      padding: 0 40px;
    }

    &__decoration--top {
      left: 0;
      width: 50%;
      padding-left: 12%;
    }

    &__item {
      flex-direction: row;
      // align-items: center;
    }

    &__decoration--line {
      padding-left: 12%;
    }

    &__image {
      order: 1;
      flex-shrink: 0;
      width: 40%;
      margin-left: 50px;
    }

    &__description {
      padding-left: 20%;

      &::after {
        left: calc(21% - 8px);
      }
    }

    &__decoration--top {
      .a {
        stroke-width: 2;
      }

      .toper {
        stroke-dasharray: 4 4;
        stroke-width: 4;
      }
    }

    &__decoration--line {
      .a {
        stroke-width: 2;
      }

      .toper {
        stroke-width: 4;
      }
    }
  }
}
