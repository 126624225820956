.progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: $color--bright-turquoise;
  transform-origin: top;
  transform: scaleY(0);
  z-index: 2;
  transition: margin 0.2s $easing--standard;

  &.push {
    margin-top: 80px;
  }
}
