.cookies {
  height: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: 0.2s $easing--standard;

  a {
    color: $color--turquoise-surf;
    font-weight: 700;
  }

  &__text {
    padding: 0 50px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto 0;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background-color: $color--arsenic;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &--open.active {
    height: 150px;
    opacity: 1;
    pointer-events: all;
    transition-delay: 0.6s;
  }

  &--offset {
    margin-top: 80px;
  }

  @include small {
    &--open.active {
      height: 120px;
    }
  }

  @include medium {
    &--open.active {
      height: 60px;
    }
  }
}
