html {
  font-size: $font-size--base;
  scroll-behavior: smooth;
}

body {
  color: $color--arsenic;
  font-family: $font-family--primary;

  &.fixed,
  &.modal-open {
    overflow: hidden;
  }
}

.container {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.section {
  &__title {
    font-family: $font-family--secondary;
    font-size: $font-size--xregular;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: $color--dark-imperial-blue;
    margin-bottom: 20px;
  }

  &__slogan {
    font-family: $font-family--primary;
    font-size: $font-size--xmedium;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    color: $color--turquoise-surf;

    &--bold {
      font-weight: 700;
    }
  }

  &__paragraph {
    font-family: $font-family--secondary;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.2;
    margin: 20px auto 0;
    max-width: 400px;

    b,
    strong {
      font-weight: 900;
      color: $color--turquoise-surf;
    }
  }

  @include medium {
    &__title {
      font-size: $font-size--medium;
      margin-bottom: 40px;
    }

    &__slogan {
      font-size: $font-size--xlarge;
      line-height: 1;
    }

    &__paragraph {
      font-size: $font-size--medium;
      max-width: 600px;
    }
  }
}
