html {
  -webkit-text-size-adjust: 100%;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
img,
input,
label,
main,
menu,
nav,
p,
section,
select,
small,
textarea {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
p,
select,
small,
textarea {
  speak: none;
  -webkit-font-smoothing: antialiased;
}

:focus {
  outline: 0;
}

textarea {
  overflow: auto;
}

input,
input:after,
input:before {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  -o-user-select: initial;
  user-select: initial;
}

input,
input:focus,
input:hover,
select,
select:focus,
select:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-ms-clear {
    display: none;
  }
}

a,
i {
  color: inherit;
  text-decoration: none;
}

input:focus,
keygen:focus,
select:focus,
textarea:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &:after,
  &:before {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

input::-ms-clear,
input::-webkit-search-cancel-button {
  display: none;
  opacity: 0;
  -webkit-appearance: none;
}

input:not([type="submit"]):not([type="password"]) {
  -moz-appearance: textfield;
}

button {
  border: none;
  background: none;
  padding: 0;
  font: inherit;
}
