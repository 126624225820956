.hero {
  height: 100vh;
  position: relative;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    z-index: -1;

    &__top,
    &__middle,
    &__bottom {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color--anti-flash-white;
      transform: scaleY(0);
      transform-origin: top;

      .active & {
        transition: 0.8s cubic-bezier(0, 0, 0.2, 1);
        transform: scaleY(1);
      }
    }

    &__middle {
      background-color: $color--dark-imperial-blue;

      .active & {
        transition-delay: 0.15s;
      }
    }

    &__top {
      background-color: $color--turquoise-surf;

      .active & {
        transition-delay: 0.3s;
      }
    }
  }

  &__container {
    height: percentage(1/3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    padding: 0 20px;
    margin: 0 auto;

    &--main {
      .hero__subtitle,
      .button {
        display: none;
      }
    }

    &--sub {
      justify-content: space-between;
    }
  }

  &__navbar {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px 0;
    z-index: 1;
    opacity: 0;

    .active & {
      opacity: 1;
      transition: 0.3s $easing--standard 1.35s;
    }

    &__left {
      svg {
        width: 100px;
        fill: $color--white;
      }
    }

    &__hamburger {
      .navbar__hamburger__line {
        background-color: $color--white;
        transition: 0.2s $easing--standard;

        &::before,
        &::after {
          background-color: $color--white;
          transition: 0.2s $easing--standard;
        }
      }

      &:hover {
        .navbar__hamburger__line {
          background-color: $color--dark-imperial-blue;

          &::before,
          &::after {
            background-color: $color--dark-imperial-blue;
          }
        }
      }
    }

    &__button,
    &__lang,
    &--menu {
      display: none;
    }
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    color: $color--dark-imperial-blue;
    font-weight: 900;
    font-size: $font-size--medium;
    line-height: 1.2;
    margin-top: 80px;
    opacity: 0;
    transform: translateY(20px);

    .active & {
      opacity: 1;
      transform: translateY(0);
      transition: 0.3s $easing--standard 0.6s;
    }
  }

  &__subtitle {
    text-align: center;
    font-family: $font-family--secondary;
    font-size: $font-size--regular;
    font-weight: 500;
    opacity: 0;
    transform: translateY(20px);

    .active & {
      opacity: 1;
      transform: translateY(0);
      transition: 0.3s $easing--standard 0.9s;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transform: translateY(20px);

    .active & {
      opacity: 1;
      transform: translateY(0);
      transition: 0.3s $easing--standard 0.75s;
    }
  }

  .button--offer {
    opacity: 0;
    transform: translateY(20px);
  }

  &.active .button--offer {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s $easing--standard,
      transform 0.3s $easing--standard 1.05s,
      opacity 0.3s $easing--standard 1.05s;
  }

  &__arrow {
    width: 20px;
    height: 20px;
    border: 1px solid $color--dark-imperial-blue;
    border-top: none;
    border-left: none;
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px) rotate(45deg);

    .active & {
      opacity: 1;
      transform: translateY(0) rotate(45deg);
      transition: 0.3s $easing--standard 1.2s;
    }
  }

  &__scroll,
  &__social {
    display: none;
  }

  @include medium {
    flex-direction: row;
    padding-bottom: 0;

    &__background {
      height: 100%;
      width: 50%;

      &__top,
      &__middle,
      &__bottom {
        transform: scaleX(0);
        transform-origin: left;

        .active & {
          transform: scaleX(1);
        }
      }
    }

    &__container {
      height: auto;
      max-width: none;
      width: 50%;
      padding: 0 100px;
      position: relative;

      &--main {
        align-items: flex-start;
        margin-right: 0;
        padding-right: 150px;

        .hero__subtitle,
        .button {
          display: inline-block;
        }
      }

      &--right {
        margin-left: 0;
        padding: 0;
      }

      &--sub {
        display: none;
      }
    }

    &__title {
      text-align: left;
      font-size: $font-size--xmedium;
      margin-bottom: 20px;
      width: 100%;
      margin-top: 30px;

      .active & {
        transition-delay: 0.6s;
      }
    }

    &__subtitle {
      text-align: left;
      color: $color--white;
      font-size: $font-size--medium;
      margin-bottom: 50px;

      .active & {
        transition-delay: 0.75s;
      }
    }

    &__image {
      position: relative;
      left: -18.5%;

      .active & {
        transition-delay: 1.05s;
      }
    }

    &.active .button--offer {
      transition: all 0.2s $easing--standard,
        transform 0.3s $easing--standard 0.9s,
        opacity 0.3s $easing--standard 0.9s;
    }

    &__scroll {
      display: flex;
      align-items: center;
      color: $color--white;
      font-family: $font-family--secondary;
      font-size: $font-size--small;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: absolute;
      bottom: 60px;
      left: 100px;
      cursor: pointer;
      opacity: 0;

      svg {
        height: 31px;
        margin-right: 15px;
        transition: 0.2s $easing--standard;
        fill: $color--white;
      }

      &:hover {
        color: $color--dark-imperial-blue;

        svg {
          fill: $color--dark-imperial-blue;
        }
      }

      .active & {
        opacity: 1;
        transition: color 0.2s $easing--standard,
          opacity 0.3s $easing--standard 1.2s;
      }
    }

    &__social {
      display: flex;
      align-items: center;
      opacity: 0;
      position: absolute;
      bottom: 60px;

      &__separator {
        height: 1px;
        width: 50px;
        margin: 0 20px;
        background-color: $color--dark-imperial-blue;

        &:last-of-type {
          display: none;
        }
      }

      a {
        color: $color--dark-imperial-blue;
        font-family: $font-family--secondary;
        font-size: $font-size--small;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        transition: 0.2s $easing--standard;

        &:hover {
          color: $color--turquoise-surf;
        }
      }

      .active & {
        opacity: 1;
        transition: 0.3s $easing--standard 1.2s;
      }
    }

    &__navbar {
      padding: 50px 20px 0 100px;

      .active & {
        transition-delay: 1.2s;
      }

      &__left {
        svg {
          width: 135px;
        }
      }

      &__right {
        display: flex;
        align-items: flex-start;
      }

      &__lang {
        display: flex;
        align-items: center;
        padding-right: 15px;
        position: relative;
        cursor: default;
        // temp
        display: none;

        svg {
          width: 26px;
          margin-right: 15px;
        }

        &__active {
          display: flex;
          align-items: center;
          color: $color--white;
          fill: $color--white;
          transition: 0.2s $easing--standard;

          &::after {
            content: '';
            width: 6px;
            height: 6px;
            border-bottom: 1px solid $color--white;
            border-right: 1px solid $color--white;
            margin-top: -3px;
            margin-left: 5px;
            transform: rotate(45deg);
            transition: 0.2s $easing--standard;
          }
        }

        &__submenu {
          background-color: $color--white;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 20px;
          margin-top: 20px;
          border-radius: 10px;
          position: absolute;
          top: 100%;
          left: 50%;
          visibility: hidden;
          opacity: 0;
          transform: translateX(-50%) translateY(10px);
          pointer-events: none;
          transition: 0.2s $easing--standard;

          &::after {
            content: '';
            width: 100%;
            height: 20px;
            position: absolute;
            bottom: 100%;
            left: 0;
          }

          &::before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            top: -10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(45deg);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: $color--white;
          }
        }

        &__item {
          a {
            margin: 0;
            margin-bottom: 10px;
            font-family: $font-family--secondary;
            color: $color--dark-imperial-blue;
            font-size: $font-size--small;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: none;
            height: 1rem;
            position: relative;
            padding-right: 20px;
            white-space: nowrap;
            transition: 0.2s $easing--standard;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
              height: 8px;
              width: 8px;
              border-right: 1px solid $color--dark-imperial-blue;
              border-top: 1px solid $color--dark-imperial-blue;
              transform: rotate(45deg);
              transition: 0.2s $easing--standard;
            }

            &:hover {
              color: $color--turquoise-surf;

              &::after {
                border-color: $color--turquoise-surf;
              }
            }
          }

          &:last-of-type a {
            margin-bottom: 0;
          }

          &:first-of-type::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 40px;
            height: 20px;
            background-color: $color--white;
          }
        }

        &:hover {
          .hero__navbar__lang__active {
            color: $color--dark-imperial-blue;
            fill: $color--dark-imperial-blue;

            &::after {
              border-color: $color--dark-imperial-blue;
            }
          }

          .hero__navbar__lang__submenu {
            visibility: visible;
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            pointer-events: all;
          }
        }
      }

      &__button {
        display: flex;
        align-items: center;
        color: $color--white;
        font-family: $font-family--secondary;
        font-size: $font-size--small;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-left: 15px;
        transition: 0.2s $easing--standard;

        svg {
          width: 26px;
          margin-right: 15px;
          fill: $color--white;
          transition: 0.2s $easing--standard;
        }

        &:hover {
          color: $color--dark-imperial-blue;

          svg {
            fill: $color--dark-imperial-blue;
          }
        }
      }

      &--menu {
        display: flex;
        padding: 50px 20px 0;
        justify-content: flex-start;

        .hero__navbar__button {
          color: $color--dark-imperial-blue;
          height: 26px;
          margin-right: 20px;
          white-space: nowrap;
        }

        .menu {
          display: flex;
        }

        .menu-item {
          a {
            display: flex;
            align-items: center;
            font-family: $font-family--secondary;
            font-size: $font-size--small;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $color--dark-imperial-blue;
            height: 26px;
            margin: 0 10px;
            white-space: nowrap;
            transition: 0.2s $easing--standard;

            &:hover {
              color: $color--turquoise-surf;
            }
          }

          &:first-of-type a {
            margin-left: 0;
          }
        }

        .menu-item-has-children {
          position: relative;

          & > a {
            cursor: default;
          }

          &:hover {
            & > a {
              color: $color--turquoise-surf;
            }

            .sub-menu {
              visibility: visible;
              opacity: 1;
              transform: translateX(-50%) translateY(0);
              pointer-events: all;
            }
          }
        }

        .sub-menu {
          position: absolute;
          top: 100%;
          left: 50%;
          background-color: $color--white;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          padding: 20px;
          margin-top: 20px;
          border-radius: 10px;
          visibility: hidden;
          opacity: 0;
          transform: translateX(-50%) translateY(10px);
          pointer-events: none;
          transition: 0.2s $easing--standard;

          &::after {
            content: '';
            width: 100%;
            height: 20px;
            position: absolute;
            bottom: 100%;
            left: 0;
          }

          &::before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            top: -10px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: rotate(45deg);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: $color--white;
          }

          .menu-item {
            a {
              margin: 0;
              margin-bottom: 10px;
              font-weight: 600;
              text-transform: none;
              height: 1rem;
              position: relative;
              padding-right: 20px;

              &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                height: 8px;
                width: 8px;
                border-right: 1px solid $color--dark-imperial-blue;
                border-top: 1px solid $color--dark-imperial-blue;
                transform: rotate(45deg);
                transition: 0.2s $easing--standard;
              }

              &:hover {
                &::after {
                  border-color: $color--turquoise-surf;
                }
              }
            }

            &:last-of-type a {
              margin-bottom: 0;
            }

            &:first-of-type::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              margin: 0 auto;
              width: 40px;
              height: 20px;
              background-color: $color--white;
            }
          }
        }
      }
    }
  }

  @include large {
    &__title {
      margin-top: 0;
      font-size: $font-size--large;
    }

    &__navbar {
      padding-right: 50px;

      &__lang {
        padding-right: 30px;
      }

      &__button {
        padding-left: 30px;
      }

      &--menu {
        padding-left: 50px;

        .menu-item a {
          margin: 0 20px;
        }
      }
    }
  }

  @include xlarge {
    &__title {
      font-size: $font-size--xlarge;
    }
  }
}
