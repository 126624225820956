.footer {
  padding: 100px 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $color--turquoise-surf;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: 0.3s $easing--decelerate;
  }

  &.active::before {
    transform: scaleY(1);
  }

  &__intro {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
    }

    .button {
      margin-top: 50px;
      opacity: 0;
      transition: 0.3s $easing--decelerate;

      .active & {
        opacity: 1;
      }
    }
  }

  .section__slogan {
    color: $color--white;
  }

  &__bottom {
    font-family: $font-family--secondary;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    color: $color--white;

    strong {
      font-weight: 700;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: calc(100% - 40px);
        height: 1px;
        background-color: $color--white;
      }
    }
    &__apps {
      display: flex;
      margin-bottom: 40px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__app {
      margin: 10px;
      height: 40px;
      fill: $color--white;
      border: 1px solid $color--white;
      border-radius: 5px;
      transition: 0.2s $easing--standard;

      svg {
        height: 100%;
      }

      &:hover {
        fill: $color--dark-imperial-blue;
        border-color: $color--dark-imperial-blue;
      }
    }

    &__copyright {
      margin-bottom: 40px;
      line-height: 1.2;

      a {
        font-weight: 700;
      }
    }

    &__solution {
      font-size: $font-size--small;

      a {
        position: relative;
        transition: 0.2s $easing--standard;

        &::before {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: $color--white;
          transform-origin: bottom;
          transform: scaleY(0);
          transition: 0.2s $easing--standard;
        }

        &:hover {
          &::before {
            transform: scaleY(1);
          }
        }

        &:active {
          color: $color--dark-imperial-blue;
          &::before {
            background-color: $color--dark-imperial-blue;
          }
        }
      }
    }
  }

  @include medium {
    padding-bottom: 50px;
    &__bottom {
      &__container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &__apps,
      &__solution {
        order: 1;
        margin: 0;
        flex-basis: 33%;
        text-align: right;
      }

      &__copyright {
        margin: 0;
        width: percentage(1/3);
        text-align: left;
      }

      &__solution {
        font-size: $font-size--regular;
      }
    }
  }
}
