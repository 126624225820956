.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: $color--white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: $font-family--secondary;
  font-size: $font-size--small;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $color--dark-imperial-blue;

  &__container {
    display: flex;
    align-items: center;
    height: 80px;
  }

  &__logo {
    margin-right: auto;
    svg {
      width: 130px;
      fill: $color--dark-imperial-blue;
    }

    &--small {
      display: none;
    }
  }

  &__menu,
  &__lang,
  &__button,
  &__offer {
    display: none;
  }

  &__hamburger {
    display: flex;
    align-items: center;
    width: 35px;
    height: 100%;
    cursor: pointer;

    &__line {
      width: 100%;
      height: 2px;
      background-color: $color--turquoise-surf;
      border-radius: 2px;
      transition: 0.2s $easing--standard;

      &::before,
      &::after {
        content: '';
        position: relative;
        top: 10px;
        width: 100%;
        height: 2px;
        background-color: $color--turquoise-surf;
        display: block;
        border-radius: 2px;
        transition: 0.2s $easing--standard;
      }

      &::before {
        top: -12px;
      }
    }
  }

  &--hidden {
    bottom: 100%;
    top: auto;
    transform: translateY(0);
    transition: 0.2s $easing--accelerate; // closing

    &.active {
      transform: translateY(100%);
      transition: 0.25s $easing--decelerate; // opening
    }
  }

  @include medium {
    &__logo {
      svg {
        width: 130px;
      }

      &--large {
        display: none;
      }

      &--small {
        display: block;
      }
    }

    &__menu {
      height: 100%;
      display: flex;

      .menu {
        display: flex;
        height: 100%;
      }

      .menu-item a {
        display: flex;
        align-items: center;
        margin: 0 15px;
        height: 100%;
        color: $color--dark-imperial-blue;
        transition: 0.2s $easing--standard;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: $color--turquoise-surf;
          transform-origin: bottom;
          transform: scaleY(0);
          transition: 0.2s $easing--standard;
        }

        &:hover {
          color: $color--turquoise-surf;
        }

        &:active,
        &.active {
          &::before {
            transform: scaleY(1);
          }
        }
      }

      .menu-item-has-children {
        position: relative;

        & > a {
          cursor: default;
        }

        &:hover {
          & > a {
            color: $color--turquoise-surf;

            &::before {
              transform: scaleY(1);
            }
          }

          .sub-menu {
            visibility: visible;
            opacity: 1;
            transform: translateX(-50%) translateY(0);
            pointer-events: all;
          }
        }
      }

      .sub-menu {
        background-color: $color--white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin-top: 30px;
        border-radius: 10px;
        position: absolute;
        top: 100%;
        left: 50%;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(10px);
        pointer-events: none;
        transition: 0.2s $easing--standard;

        &::after {
          content: '';
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 100%;
          left: 0;
        }

        &::before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          top: -10px;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform: rotate(45deg);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          background-color: $color--white;
        }

        .menu-item {
          a {
            margin: 0;
            margin-bottom: 10px;
            font-weight: 600;
            text-transform: none;
            height: 1rem;
            position: relative;
            padding-right: 20px;
            white-space: nowrap;

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
              height: 8px;
              width: 8px;
              border-right: 1px solid $color--dark-imperial-blue;
              border-top: 1px solid $color--dark-imperial-blue;
              transform: rotate(45deg);
              transition: 0.2s $easing--standard;
            }

            &::before {
              display: none;
            }

            &:hover {
              &::after {
                border-color: $color--turquoise-surf;
              }
            }
          }

          &:last-of-type a {
            margin-bottom: 0;
          }

          &:first-of-type::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 40px;
            height: 20px;
            background-color: $color--white;
          }
        }
      }
    }

    &__lang {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 15px;
      position: relative;
      cursor: default;
      // temp
      display: none;

      svg {
        width: 26px;
        margin-right: 15px;
      }

      &__active {
        display: flex;
        align-items: center;
        color: $color--turquoise-surf;
        fill: $color--turquoise-surf;
        transition: 0.2s $easing--standard;

        &::after {
          content: '';
          width: 6px;
          height: 6px;
          border-bottom: 1px solid $color--turquoise-surf;
          border-right: 1px solid $color--turquoise-surf;
          margin-top: -3px;
          margin-left: 5px;
          transform: rotate(45deg);
          transition: 0.2s $easing--standard;
        }
      }

      &__submenu {
        background-color: $color--white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin-top: 30px;
        border-radius: 10px;
        position: absolute;
        top: 100%;
        left: 50%;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(10px);
        pointer-events: none;
        transition: 0.2s $easing--standard;

        &::after {
          content: '';
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 100%;
          left: 0;
        }

        &::before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          top: -10px;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform: rotate(45deg);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          background-color: $color--white;
        }
      }

      &__item {
        a {
          margin: 0;
          margin-bottom: 10px;
          font-family: $font-family--secondary;
          color: $color--dark-imperial-blue;
          font-size: $font-size--small;
          letter-spacing: 1px;
          font-weight: 600;
          text-transform: none;
          height: 1rem;
          position: relative;
          padding-right: 20px;
          white-space: nowrap;
          transition: 0.2s $easing--standard;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            height: 8px;
            width: 8px;
            border-right: 1px solid $color--dark-imperial-blue;
            border-top: 1px solid $color--dark-imperial-blue;
            transform: rotate(45deg);
            transition: 0.2s $easing--standard;
          }

          &:hover {
            color: $color--turquoise-surf;

            &::after {
              border-color: $color--turquoise-surf;
            }
          }
        }

        &:last-of-type a {
          margin-bottom: 0;
        }

        &:first-of-type::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 40px;
          height: 20px;
          background-color: $color--white;
        }
      }

      &:hover {
        .navbar__lang__active {
          color: $color--dark-imperial-blue;
          fill: $color--dark-imperial-blue;

          &::after {
            border-color: $color--dark-imperial-blue;
          }
        }

        .navbar__lang__submenu {
          visibility: visible;
          opacity: 1;
          transform: translateX(-50%) translateY(0);
          pointer-events: all;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      color: $color--turquoise-surf;
      margin: 0 15px;
      height: 100%;
      position: relative;
      transition: 0.2s $easing--standard;

      svg {
        width: 26px;
        fill: $color--turquoise-surf;
        margin-right: 15px;
        transition: 0.2s $easing--standard;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color--dark-imperial-blue;
        transform-origin: bottom;
        transform: scaleY(0);
        transition: 0.2s $easing--standard;
      }

      &:hover {
        color: $color--dark-imperial-blue;

        svg {
          fill: $color--dark-imperial-blue;
        }
      }

      &:active {
        &::before {
          transform: scaleY(1);
        }
      }
    }

    &__offer {
      display: block;
      margin-left: 15px;
    }

    &__hamburger {
      display: none;
    }
  }
}
