.offer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px 0;
  z-index: 5;
  background-color: transparentize($color--white, 0.05);
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s $easing--standard;

  &.active {
    visibility: visible;
    opacity: 1;

    .offer-modal__container {
      transform: translateY(0);
    }
  }

  &__close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 1px;
      background-color: $color--arsenic;
      transition: 0.2s $easing--standard;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: $color--dark-imperial-blue;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateY(20px);
    transition: 0.2s $easing--standard;
  }

  &__image {
    width: 100%;
    object-fit: contain;
    max-height: 140px;
  }

  &__form {
    margin-top: 20px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family--secondary;
    font-size: $font-size--small;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 2;
    margin-bottom: 20px;

    input {
      border: none;
      border-bottom: 1px solid transparentize($color--arsenic, 0.8);
      background-color: transparent;
      font-family: $font-family--secondary;
      font-size: $font-size--regular;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 2;
      color: $color--arsenic;
      margin: 0 auto;
      max-width: 400px;
      width: 100%;
      transition: 0.2s $easing--standard;

      &.wpcf7-not-valid {
        border-color: red;
      }

      &:focus {
        border-color: $color--turquoise-surf;
      }
    }
  }

  .wpcf7 {
    width: 100%;
  }

  .wpcf7-form-control-wrap {
    width: 100%;
  }

  .wpcf7-submit {
    margin: 40px auto 0;
  }

  .wpcf7-response-output,
  .wpcf7-not-valid-tip,
  .ajax-loader {
    display: none !important;
  }

  @include medium {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__container {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__intro {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .section__slogan {
        margin: 0 5px;
      }
    }

    &__image {
      width: 50%;
      order: 1;
      max-height: none;
    }

    .wpcf7 {
      order: 2;
      width: 50%;
    }
  }
}
