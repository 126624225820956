.how {
  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
  }

  &__tabs {
    position: relative;
    padding-bottom: 50px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50%;
      width: 100%;
      background-color: $color--anti-flash-white;
      z-index: -1;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: 0.3s $easing--standard;
    }

    &.active::before {
      transform: scaleY(1);
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 40px;
    }

    &__top {
      flex-grow: 2;
    }

    &__bottom {
      flex-grow: 1;
      width: 100%;
      .how__tabs__content {
        display: none;
      }
    }

    &__switches {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 500px;
      margin: 0 auto;
    }
  }

  &__tab {
    display: none;

    &__image {
      object-fit: contain;
      max-height: 300px;
      width: 100%;
      transition: 0.2s $easing--standard;
    }

    &__description {
      text-align: center;
      margin-bottom: 20px;
    }

    &__title {
      font-family: $font-family--primary;
      font-size: $font-size--xmedium;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &__paragraph {
      font-family: $font-family--secondary;
      letter-spacing: 1px;
      line-height: 1.8;
    }

    &.active {
      display: block;
    }

    &.fade-in {
      animation: fadeIn 0.2s;
    }

    &.fade-out {
      animation: fadeOut 0.2s;
    }
  }

  &__switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: percentage(1/3);

    &__icon {
      height: 70px;
      width: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      stroke: $color--arsenic;
      transition: 0.2s $easing--standard;

      svg {
        height: 60%;
        width: 60%;
        z-index: 1;
        position: relative;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: $color--turquoise-surf;
        transform-origin: center;
        transform: scale(0);
        transition: 0.2s $easing--standard;
      }

      &:hover {
        stroke: $color--turquoise-surf;
      }
    }

    &__text {
      font-family: $font-family--secondary;
      font-size: $font-size--small;
      color: $color--arsenic;
      font-weight: 500;
      text-align: center;
      margin-top: 15px;
      user-select: none;
    }

    &__illustration {
      width: 100%;
      margin-top: 10px;
      padding: 0 16%;
    }

    &.active {
      pointer-events: none;

      .how__switch__icon {
        opacity: 1;
        stroke: $color--white;

        &::before {
          transform: scale(1);
        }
      }
    }
  }

  @include medium {
    &__intro {
      height: 100vh;
    }

    &__tabs {
      padding: 50px 0;
      &::before {
        height: 100%;
        width: 50%;
        transform-origin: left;
        transform: scaleX(0);
      }

      &.active::before {
        transform: scaleX(1);
      }

      &__top {
        .how__tab__description {
          display: none;
        }
      }

      &__bottom {
        display: flex;
        .how__tabs__content {
          display: block;
          width: 50%;
        }

        .how__tab__image {
          display: none;
        }
      }

      &__switches {
        width: 50%;
        padding: 0 100px;
        max-width: none;
        align-content: flex-start;
      }
    }

    &__tab {
      &__image {
        max-height: 450px;
        margin-bottom: 40px;
      }

      &__title {
        font-weight: 900;
        color: $color--platinum;
      }

      &__description {
        text-align: left;
        padding-left: 100px;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}
