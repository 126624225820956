.mobile-menu {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: $color--anti-flash-white;
  z-index: 4;
  overflow-y: auto;
  transform: translateX(0);
  transition: 0.2s $easing--accelerate; // closing

  &.active {
    transform: translateX(-100%);
    transition: 0.25s $easing--decelerate; // opening
  }

  &__container {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
  }

  &__lang {
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    // temp
    display: none;
    r svg {
      width: 26px;
      margin-right: 15px;
    }

    &__active {
      display: flex;
      align-items: center;
      color: $color--turquoise-surf;
      fill: $color--turquoise-surf;

      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-bottom: 1px solid $color--turquoise-surf;
        border-right: 1px solid $color--turquoise-surf;
        margin-top: -3px;
        margin-left: 5px;
        transform: rotate(45deg);
      }
    }

    &__submenu {
      background-color: $color--white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin-top: 30px;
      border-radius: 10px;
      position: absolute;
      top: 100%;
      left: 50%;
      visibility: hidden;
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
      pointer-events: none;
      transition: 0.2s $easing--standard;

      &::after {
        content: '';
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 100%;
        left: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: rotate(45deg);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: $color--white;
      }
    }

    &__item {
      a {
        margin: 0;
        margin-bottom: 10px;
        font-family: $font-family--secondary;
        color: $color--dark-imperial-blue;
        font-size: $font-size--small;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: none;
        height: 1rem;
        position: relative;
        padding-right: 20px;
        white-space: nowrap;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: 8px;
          width: 8px;
          border-right: 1px solid $color--dark-imperial-blue;
          border-top: 1px solid $color--dark-imperial-blue;
          transform: rotate(45deg);
        }
      }

      &:last-of-type a {
        margin-bottom: 0;
      }

      &:first-of-type::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 40px;
        height: 20px;
        background-color: $color--white;
      }
    }

    &:hover .mobile-menu__lang__submenu {
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      pointer-events: all;
    }
  }

  &__close {
    width: 40px;
    height: 40px;
    position: relative;
    left: 20px;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 1px;
      background-color: $color--arsenic;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    user-select: none;

    .menu-item {
      font-family: $font-family--secondary;
      font-size: $font-size--medium;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $color--dark-imperial-blue;
      margin-bottom: 60px;

      a {
        display: inline-block;
        width: 100%;
      }
    }

    .menu-item-has-children {
      & > a {
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: 6px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          width: 10px;
          height: 2px;
          border-radius: 2px;
          background-color: $color--dark-imperial-blue;
          transform: rotate(45deg);
          transition: 0.2s $easing--standard;
        }

        &::after {
          right: 0;
          transform: rotate(-45deg);
        }
      }

      .sub-menu {
        height: 0;
        overflow: hidden;
        will-change: height;
        transition: 0.4s $easing--standard;
      }

      &.active {
        & > a {
          &::before {
            transform: rotate(-45deg);
          }

          &::after {
            transform: rotate(45deg);
          }
        }

        .sub-menu {
          height: auto;
        }
      }
    }

    .sub-menu .menu-item {
      text-transform: none;
      font-size: $font-size--regular;
      font-weight: 400;
      margin: 30px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__offer {
    margin: 0 auto;
  }
}
