.partners {
  padding: 100px 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color--anti-flash-white;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: 0.3s $easing--decelerate;
  }

  &.active::before {
    transform: scaleY(1);
  }

  &__intro {
    .section__slogan {
      color: $color--arsenic;
      opacity: 0.5;
    }
  }

  &__logos {
    margin-top: 100px;
    position: relative;
    &__container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__logo {
    max-width: 110px;
    max-height: 70px;
    width: 100%;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 10px;
    }
  }

  @include medium {
    &__logo {
      &__container {
        width: auto;
        padding: 0;
      }
    }
  }
}
