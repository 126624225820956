@mixin bgCover() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin bgContain() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

// Breakpoints
// Mobile-first

// 0px-400px
@mixin xsmall {
  @media (min-width: map-get($breakpoints, "xsmall")) {
    @content;
  }
}

// 400px-640px
@mixin small {
  @media (min-width: map-get($breakpoints, "small")) {
    @content;
  }
}

// 640px-960px
@mixin medium {
  @media (min-width: map-get($breakpoints, "medium")) {
    @content;
  }
}

// 960px-1200px
@mixin large {
  @media (min-width: map-get($breakpoints, "large")) {
    @content;
  }
}

// 1200px-1600px
@mixin xlarge {
  @media (min-width: map-get($breakpoints, "xlarge")) {
    @content;
  }
}
