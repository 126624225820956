.button {
  padding: 17px 25px;
  text-transform: uppercase;
  font-family: $font-family--secondary;
  font-size: $font-size--small;
  font-weight: 600;
  letter-spacing: 1px;
  color: $color--white;
  border-radius: 25px;
  background-color: $color--turquoise-surf;
  box-shadow: 0 10px 20px transparentize($color--turquoise-surf, 0.8);
  cursor: pointer;
  border: none;
  transition: 0.2s $easing--standard;

  &:hover {
    box-shadow: none;
  }

  &:active {
    background-color: $color--dark-imperial-blue;
  }

  &--white {
    box-shadow: none;
    background-color: $color--white;
    color: $color--turquoise-surf;

    &:hover {
      color: $color--dark-imperial-blue;
    }

    &:active {
      background-color: $color--dark-imperial-blue;
      color: $color--turquoise-surf;
    }
  }
}
