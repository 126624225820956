h1,
h2,
h3 {
  font-family: $font-family--primary;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

h1 {
  font-size: $font-size--large;
}
