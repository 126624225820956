.solution {
  padding: 50px 0 100px;

  &__swiper {
    margin-top: 50px;
    width: 100%;

    .swiper-slide {
      width: 26%;
      min-width: 150px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        height: 100%;
        width: 1px;
        background-color: $color--arsenic;
        opacity: 0.2;
      }

      &:last-of-type::before {
        display: none;
      }
    }

    &__number {
      font-size: $font-size--xxxlarge;
      font-weight: 900;
      color: $color--platinum;
    }

    &__description {
      font-family: $font-family--secondary;
      font-size: $font-size--small;
      line-height: 1.2;
      letter-spacing: 1px;
      text-align: center;
      color: $color--arsenic;
    }
  }

  @include medium {
    padding: 100px 0 0;

    &__intro {
      .section__paragraph {
        max-width: 800px;
      }
    }

    &__swiper {
      max-width: 1200px;
      margin: 100px auto 0;

      .swiper-wrapper {
        flex-wrap: wrap;
        justify-content: center;
      }
      .swiper-slide {
        max-width: 200px;
        margin-bottom: 100px;
      }

      &__description {
        font-size: $font-size--xregular;
      }
    }
  }
}
